import { gql } from "@apollo/client";

const GET_CAMPAIGN_RESULTS = gql`
  query getCampaignResults($userIds: [ID!]!) {
    getCampaignResults(userIds: $userIds) {
      id
      userId
      campaignId
      instaImpressions
      instaLikes
      instaComments
      instaFollowers
      instaStoryViews
      instaReach
      tiktokLikes
      tiktokComments
      tiktokFollowers
      tiktokViews
      tiktokShares
      discountCodeUsed
      instaNonFollowers
      instaSaves
      instaShares
      instaProfileVisits
      instaAccountsReached
      instaAccountsEngaged
      instaLinkClicks
      tiktokAverageWatchTime
      tiktokWatchFullVideo
      tiktokPlays
      tiktokSaves
      tiktokShares
      updatedAt
      campaign {
        name
      }
    }
  }
`;

export default GET_CAMPAIGN_RESULTS;
